<template>
    <div class="gumi-header">
        <div class="container">
            <div
                class="main-header d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3"
            >
                <router-link
                    to="/"
                    class="d-flex align-items-center col-lg-2 mb-2 mb-md-0 text-dark text-decoration-none"
                >
                    <img src="/images/logo_header.png" />
                </router-link>
                <ul
                    class="mainMenu nav col-10 col-lg-auto mb-2 justify-content-center mb-md-0"
                    v-if="model && model.items"
                >
                    <template v-for="(item, index) in model.items">
                        <li
                            v-bind:class="{
                                active: $route.path === item.link
                            }"
                            v-bind:key="'menu-1-' + index"
                        >
                            <router-link
                                :to="item.link"
                                class="nav-link link-dark menuElement"
                                >{{ item.title }}</router-link
                            >
                        </li>
                    </template>
                </ul>
                <div class="col-lg-3 menuIcon">
                    <a href="https://www.point-s.hu/" target="_blank">
                        <img
                            class="menuElement"
                            src="/images/point_s_logo.png"
                        />
                    </a>
                    <i
                        id="search"
                        class="icon-search"
                        v-on:click="openSearch"
                    ></i>
                    <i id="cart" class="icon-basket nagy" v-on:click="openCart">
                        <div class="count" v-if="cartQuantity > 0">
                            {{ cartQuantity }}
                        </div>
                    </i>
                    <div
                        class="search-items"
                        v-bind:class="{ hidden: showSearch }"
                    >
                        <div class="items-box">
                            <input
                                type="text"
                                name="search"
                                v-model="s"
                                v-on:keyup="startSearch"
                                placeholder="Keresés..."
                            />
                            <router-link
                                tag="div"
                                class="search-item"
                                style="cursor: pointer"
                                v-for="(productData, index) in results"
                                v-bind:key="'results-' + index"
                                :to="{
                                    name: 'Product',
                                    params: {
                                        slug: productData.kod2,
                                        emarka: productData.emarka,
                                        width: productData.width,
                                        profile: productData.profile,
                                        coll: productData.coll
                                    }
                                }"
                            >
                                <div class="row">
                                    <div class="col-lg-4">
                                        <img
                                            v-if="
                                                productData.globalPattern &&
                                                    productData.globalPattern
                                                        .image
                                            "
                                            :src="
                                                productData.globalPattern.image
                                            "
                                        />
                                        <img v-else :src="productData.kepnev" />
                                    </div>
                                    <div class="col-lg-8">
                                        <h3 class="item-name">
                                            {{ productData.emarka }}
                                            {{ productData.pattern }}
                                            {{ productData.m1nev }}
                                            {{ productData.li }}
                                            {{ productData.si }}
                                            {{ productData.xlfr }}
                                        </h3>
                                        <span class="item-type"
                                            >{{ productData.width }}/{{
                                                productData.profile
                                            }}R{{ productData.coll }}</span
                                        >
                                        <span
                                            class="item-quantity"
                                            v-if="
                                                productData.tat <= 6 &&
                                                    productData.tat !== 0
                                            "
                                            >{{ productData.tat }} db</span
                                        >
                                        <span
                                            class="item-price sale"
                                            v-bind:class="{
                                                underlined:
                                                    productData.szemar !== 0
                                            }"
                                        >
                                            Ár:
                                            {{
                                                productData.calculated_special_price
                                            }}
                                            / db
                                        </span>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div
                        class="shopping-cart"
                        v-bind:class="{ hidden: showCart }"
                    >
                        <div
                            class="shopping-cart-items"
                            v-if="cart && Object.keys(cart).length > 0"
                        >
                            <div
                                class="cart-item"
                                v-for="(item, index) in cart.items"
                                v-bind:key="'cart-' + index"
                            >
                                <div class="row">
                                    <div class="col-lg-4">
                                        <img
                                            v-if="
                                                item.product.globalPattern &&
                                                    item.product.globalPattern
                                                        .image
                                            "
                                            :src="
                                                item.product.globalPattern.image
                                            "
                                        />
                                        <img
                                            v-else
                                            :src="item.product.kepnev"
                                        />
                                    </div>
                                    <div class="col-lg-8">
                                        <h3 class="item-name">
                                            {{ item.product.emarka }}
                                            {{ item.product.pattern }}
                                            {{ item.product.m1nev }}
                                            {{ item.product.li }}
                                            {{ item.product.si }}
                                            {{ item.product.xlfr }}
                                        </h3>
                                        <span class="item-type"
                                            >{{ item.product.width }}/{{
                                                item.product.profile
                                            }}R{{ item.product.coll }}</span
                                        >
                                        <span class="item-quantity"
                                            >{{ item.quantity }} db</span
                                        >
                                        <span
                                            class="item-price sale"
                                            v-bind:class="{
                                                underlined:
                                                    item.product.szemar !== 0
                                            }"
                                        >
                                            Ár:
                                            {{
                                                item.product
                                                    .calculated_special_price
                                            }}
                                            / db
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row totals">
                                <span class="totalText col-lg-6">Összesen</span>
                                <span class="totalAmount col-lg-6"
                                    >{{ cart.cart_total.total_f }} Ft</span
                                >
                            </div>
                            <router-link
                                tag="button"
                                :to="{ name: 'Cart' }"
                                class="transition"
                                id="mycart"
                                >Kosaram</router-link
                            >
                            <router-link
                                :to="{ name: 'CheckoutBilling' }"
                                tag="button"
                                class="transition"
                                id="checkout"
                            >
                                Kasszához
                            </router-link>
                        </div>
                    </div>
                    <!--end shopping-cart -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
    name: "Header",
    data() {
        return {
            cart: {},
            user: {},
            model: {},
            s: null,
            results: [],
            showSearch: true,
            showCart: true,
            timeOut: null
        };
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_URL;
        },
        cartQuantity: function() {
            let qty = 0;
            if (this.cart && this.cart.items) {
                for (const it in this.cart.items) {
                    qty += this.cart.items[it].quantity;
                }
            }

            return qty;
        }
    },
    watch: {
        $route: {
            handler: function() {
                this.showSearch = true;
                this.showCart = true;
            },
            deep: true
        }
    },
    beforeMount() {
        const self = this;
        self.getUserData();
        self.getSearchData();
        self.getCart();

        self.$eventHub.$on("updateCart", function() {
            self.getCart();
        });
    },
    methods: {
        getCart() {
            const cartUuid = $cookies.get("cart_uuid");
            ApiService.query(`${this.url}/cart?uuid=${cartUuid}`).then(
                response => {
                    $cookies.set("cart_uuid", response.data.data.uuid, 864000);
                    this.cart = response.data.data;
                }
            );
        },
        startSearch() {
            const self = this;
            clearTimeout(self.timeOut);
            self.timeOut = setTimeout(function() {
                if (self.s === null || self.s === "") {
                    self.results = [];
                } else {
                    ApiService.query(
                        `${self.url}/products/search-product?s=${self.s}`
                    ).then(response => {
                        self.results = response.data.data;
                    });
                }
            }, 500);
        },
        openCart() {
            this.showCart = !this.showCart;
            this.showSearch = true;
        },
        openSearch() {
            this.showSearch = !this.showSearch;
            this.showCart = true;
        },
        logout() {
            $cookies.remove("user");
            $cookies.remove("id_token");
            document.location.href = "/";
        },
        getSearchData() {
            ApiService.get(`${this.url}/menu/get/1`).then(response => {
                this.model = response.data.data;
            });
        },
        getUserData() {
            if ($cookies.get("id_token")) {
                ApiService.get(`${this.url}/user/profile`).then(response => {
                    this.user = response.data.data;
                    $cookies.set("user", response.data.data, 86400);
                });
            }
        }
    }
};
</script>

<style>
.hidden {
    display: none;
}
</style>

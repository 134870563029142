<template>
    <section
        class="mainSection"
        v-bind:class="{
            summerseason:
                this.Homepage_model.season &&
                this.Homepage_model.season.settings_value === 'nyárigumi'
        }"
    >
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="filterBoxContainer">
                        <b-tabs
                            content-class="filterbox "
                            v-bind:class="{
                                changecalculatoractive: changecalculator_state
                            }"
                        >
                            <b-tab
                                title="Gumikereső"
                                title-link-class="filterbtn transition flex-item nav-link"
                                v-on:click="ChangeCalculator_active(false)"
                            >
                                <Tyre
                                    :filters="filters"
                                    :form="form"
                                    v-if="
                                        filters &&
                                            Object.keys(filters).length > 0 &&
                                            Object.keys(filters.emarka).length >
                                                0
                                    "
                                    v-on:goToListPage="goToListPage"
                                />
                            </b-tab>
                            <!--<b-tab
                                title="Keresés autótípus szerint"
                                title-link-class="filterbtn transition flex-item nav-link"
                                v-on:click="ChangeCalculator_active(false)"
                            >
                                <Car
                                    :filters="filters"
                                    :form="form"
                                    v-if="
                                        filters &&
                                            Object.keys(filters).length > 0 &&
                                            Object.keys(filters.emarka).length >
                                                0
                                    "
                                    v-on:goToListPage="goToListPage"
                                />
                            </b-tab>-->
                            <b-tab
                                title="Váltóméret kalkulátor"
                                title-link-class="filterbtn transition flex-item nav-link"
                                v-on:click="ChangeCalculator_active(true)"
                            >
                                <ChangeCalculator
                                    :filters="filters"
                                    :form="form"
                                    v-on:goToListPage="goToListPage"
                                />
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
//import Multiselect from "vue-multiselect";

import ApiService from "@/core/services/api.service";
import Tyre from "@/components/search/Tyre";
// import Car from "@/components/search/Car";
import ChangeCalculator from "@/components/search/ChangeCalculator";
export default {
    name: "TyreSearch",
    components: { ChangeCalculator, /*Car,*/ Tyre },
    //components: { multiselect: Multiselect },
    data() {
        return {
            changecalculator_state: false,
            filters: {
                coll: {},
                profile: {},
                width: {},
                emarka: {},
                m1nev: {},
                m2nev: {},
                si: {},
                li: {}
            },
            form: {
                coll: 16,
                profile: 55,
                width: 205,
                emarka: null,
                m1nev: null,
                m2nev: null,
                si: null,
                li: null,
                defektt: null,
                xlfr: null,
                tat: true
            },
            numberOfProducts: 0,
            Homepage_model: {}
        };
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_URL;
        }
    },
    watch: {
        form: {
            handler: function() {
                this.getCountData();
            },
            deep: true
        },
        $route: {
            handler: function() {
                this.setSearchParams();
            },
            deep: true
        }
    },
    beforeMount() {
        this.setSearchParams();
        this.getSearchData();
        this.getHomepageData();
    },
    mounted() {
        this.setSearchParams();
    },
    methods: {
        ChangeCalculator_active(input) {
            this.changecalculator_state = input;
        },
        setDefaultSearch() {
            this.form = {
                coll: 16,
                profile: 55,
                width: 205,
                emarka: null,
                m1nev: null,
                m2nev: null,
                si: null,
                li: null,
                defektt: null,
                xlfr: null,
                tat: true
            };
        },
        setEmarka() {
            if (this.$route.query.emarka) {
                this.form.emarka = [];
                let helper = this.$route.query.emarka.split(",");
                for (const it in this.filters.emarka) {
                    for (const xit in helper) {
                        if (this.filters.emarka[it].emarka === helper[xit]) {
                            this.form.emarka.push(this.filters.emarka[it]);
                        }
                    }
                }
            }
        },
        getHomepageData() {
            ApiService.get(`${this.url}/page/home`).then(response => {
                this.Homepage_model = response.data;
            });
        },
        setSearchParams() {
            if (this.$route.name === "ProductList") {
                if (this.$route.query.m1nev) {
                    this.form.m1nev = this.$route.query.m1nev;
                }
                if (this.$route.query.li) {
                    this.form.li = this.$route.query.li;
                }
                if (this.$route.query.si) {
                    this.form.si = this.$route.query.si;
                }

                this.setEmarka();

                if (this.$route.query.width) {
                    this.form.width = this.$route.query.width;
                } else this.form.width = null;
                if (this.$route.query.profile) {
                    this.form.profile = this.$route.query.profile;
                } else this.form.profile = null;
                if (this.$route.query.coll) {
                    this.form.coll = this.$route.query.coll;
                } else this.form.coll = null;

                if (this.$route.query.xlfr) {
                    this.form.xlfr = this.$route.query.xlfr;
                } else this.form.xlfr = null;

                if (this.$route.query.defektt) {
                    this.form.defektt = this.$route.query.defektt;
                } else this.form.defektt = null;

                if (this.$route.query.tat) {
                    this.form.tat = this.$route.query.tat;
                } else this.form.tat = null;

                if (this.$route.path.includes("teli-gumi")) {
                    this.form.m1nev = "téligumi";
                }

                if (this.$route.path.includes("nyari-gumi")) {
                    this.form.m1nev = "nyárigumi";
                }

                if (this.$route.path.includes("negyevszakos-gumi")) {
                    this.form.m1nev = "négyévszakos";
                }

                const helper = this.$route.params.slug.split("-");
                if (!isNaN(parseInt(helper[0]))) {
                    this.form.width = helper[0];
                    this.form.profile = helper[1];
                    this.form.coll = helper[2];
                } else if (helper[1] === "nyari" || helper[1] === "teli") {
                    this.form.emarka = helper[0];
                }

                if (helper[0] === "nyari" || helper[0] === "teli") {
                    if (helper.length > 3) {
                        this.form.width = helper[2];
                        this.form.profile = helper[3];
                        this.form.coll = helper[4];
                    }
                }
            }
        },
        goToListPage() {
            let helper = "";

            for (const it in this.form.emarka) {
                helper += this.form.emarka[it].emarka + ",";
            }

            helper = helper.replace(/,\s*$/, "");

            this.$router.push({
                name: "ProductList",
                params: {
                    slug:
                        this.form.width +
                        "-" +
                        this.form.profile +
                        "-" +
                        this.form.coll
                },
                query: {
                    emarka: helper,
                    m1nev: this.form.m1nev,
                    m2nev: this.form.m2nev,
                    si: this.form.si,
                    li: this.form.li,
                    defektt: this.form.defektt,
                    xlfr: this.form.xlfr,
                    tat: this.form.tat
                }
            });
        },
        getSearchData() {
            const self = this;
            self.$http
                .get(`${self.url}/products/search`)
                .then(response => {
                    self.filters = response.data;
                    self.setEmarka();
                    self.form.m1nev = response.data.season.settings_value;
                })
                .finally(() => {
                    self.getCountData();
                });
        },
        getCountData() {
            const self = this;

            let formHelper = JSON.parse(JSON.stringify(self.form));
            let helper = "";

            for (const it in formHelper.emarka) {
                helper += formHelper.emarka[it].emarka + ",";
            }

            helper = helper.replace(/,\s*$/, "");
            formHelper.emarka = helper;

            this.$http
                .get(
                    `${self.url}/products/product-count?${self.buildQueryString(
                        formHelper
                    )}`
                )
                .then(response => {
                    this.numberOfProducts = response.data.products;
                });
        }
    }
};
</script>

<style scoped></style>

<template>
    <div class="col-lg-4">
        <router-link :to="{ name: 'BlogPost', params: { slug: blog.slug } }">
            <div class="cardBox transition">
                <img :src="blog.main_image" />
                <div class="articleContent">
                    <h3>{{ blog.title }}</h3>
                    <span class="articleDate">{{ blog.publication_date }}</span>
                    <p>{{ blog.lead }}</p>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "BlogRow",
    props: ["blog"],
    computed: {
        appUrl: function() {
            return process.env.VUE_APP_APP_URL;
        }
    }
};
</script>

<style scoped></style>

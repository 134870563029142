<template>
    <div>
        <section class="secondSection">
            <div class="container">
                <div class="row">
                    <div
                        class="col-lg-3"
                        v-for="(item, index) in marketing"
                        v-bind:key="'marketing-' + index"
                    >
                        <div class="cardBox transition">
                            <a :href="item.link" target="_blank">
                                <img :src="item.image" />
                                <div class="row">
                                    <div class="circle col-sm-2">
                                        <i v-bind:class="item.mini_logo"></i>
                                    </div>
                                    <div class="inline-textbox col-sm-8">
                                        <h3>{{ item.name }}</h3>
                                        <p v-html="item.description"></p>
                                    </div>
                                </div>
                                <div
                                    class="cardBoxArrow icon-arrow_2 transition"
                                ></div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="listSection">
            <div class="container">
                <div class="headline">
                    <h1
                        v-if="
                            model &&
                                model.season &&
                                model.season.settings_value === 'téligumi'
                        "
                    >
                        Népszerű téli gumik
                    </h1>
                    <h1 v-else>Népszerű nyári gumik</h1>
                    <hr />
                    <template
                        v-if="
                            model &&
                                model.season &&
                                model.season.settings_value === 'téligumi'
                        "
                    >
                        <router-link
                            tag="button"
                            :to="{
                                name: 'ProductList',
                                params: { slug: 'téligumi' }
                            }"
                            class="grayButton transition"
                            >Összes téli gumi</router-link
                        >
                    </template>
                    <template v-else>
                        <router-link
                            tag="button"
                            :to="{
                                name: 'ProductList',
                                params: { slug: 'nyárigumi' }
                            }"
                            class="grayButton transition"
                            >Összes nyári gumi</router-link
                        >
                    </template>
                </div>
                <ProductRow
                    v-for="(item, index) in model.products"
                    v-bind:key="'prod-' + index"
                    :product-data="item"
                />
            </div>
        </section>
        <section class="articleSection">
            <div class="container">
                <div class="headline">
                    <h1>Legfrissebb cikkek</h1>
                    <hr />
                    <router-link
                        tag="button"
                        to="/blog"
                        class="grayButton transition"
                    >
                        További cikkek
                    </router-link>
                </div>
                <div class="row" v-if="model && model.blog">
                    <BlogRow
                        v-for="(item, index) in model.blog"
                        v-bind:key="'home-blog' + index"
                        :blog="item"
                    ></BlogRow>
                </div>
            </div>
        </section>
        <section class="partnerSection">
            <div class="container">
                <div class="headline">
                    <h1>Gumigyártók</h1>
                    <hr />
                </div>
                <div class="row partners">
                    <div
                        class="col-lg-2"
                        v-for="(item, index) in model.marka"
                        v-bind:key="'marka-' + index"
                    >
                        <router-link
                            :to="{
                                name: 'ProductList',
                                params: {
                                    slug: 'null-null-null'
                                },
                                query: { emarka: item.name }
                            }"
                        >
                            <img class="img img-fluid" :src="item.image" />
                        </router-link>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import BlogRow from "@/components/BlogRow";
import ProductRow from "@/components/ProductRow";

export default {
    name: "Home",
    components: { ProductRow, BlogRow },
    data() {
        return {
            model: {},
            marketing: []
        };
    },
    mounted() {
        if ($(".mainSection").length > 0) {
            $("html, body").animate(
                {
                    scrollTop: $(".mainSection").offset().top - 150
                },
                1000
            );
        }
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_URL;
        }
    },
    beforeMount() {
        this.getSearchData();
        this.getMarketing();
    },
    methods: {
        getMarketing() {
            ApiService.get(`${this.url}/marketing/list`).then(response => {
                this.marketing = response.data.data;
            });
        },
        getSearchData() {
            ApiService.get(`${this.url}/page/home`).then(response => {
                this.model = response.data;
                if (this.model.products && this.model.products.length > 0) {
                    this.model.products = this.model.products.sort(
                        (a, b) => a.netto - b.netto
                    );
                }
            });
        }
    }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"secondSection"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},_vm._l((_vm.marketing),function(item,index){return _c('div',{key:'marketing-' + index,staticClass:"col-lg-3"},[_c('div',{staticClass:"cardBox transition"},[_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_c('img',{attrs:{"src":item.image}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"circle col-sm-2"},[_c('i',{class:item.mini_logo})]),_c('div',{staticClass:"inline-textbox col-sm-8"},[_c('h3',[_vm._v(_vm._s(item.name))]),_c('p',{domProps:{"innerHTML":_vm._s(item.description)}})])]),_c('div',{staticClass:"cardBoxArrow icon-arrow_2 transition"})])])])}),0)])]),_c('section',{staticClass:"listSection"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"headline"},[(
                        _vm.model &&
                            _vm.model.season &&
                            _vm.model.season.settings_value === 'téligumi'
                    )?_c('h1',[_vm._v(" Népszerű téli gumik ")]):_c('h1',[_vm._v("Népszerű nyári gumik")]),_c('hr'),(
                        _vm.model &&
                            _vm.model.season &&
                            _vm.model.season.settings_value === 'téligumi'
                    )?[_c('router-link',{staticClass:"grayButton transition",attrs:{"tag":"button","to":{
                            name: 'ProductList',
                            params: { slug: 'téligumi' }
                        }}},[_vm._v("Összes téli gumi")])]:[_c('router-link',{staticClass:"grayButton transition",attrs:{"tag":"button","to":{
                            name: 'ProductList',
                            params: { slug: 'nyárigumi' }
                        }}},[_vm._v("Összes nyári gumi")])]],2),_vm._l((_vm.model.products),function(item,index){return _c('ProductRow',{key:'prod-' + index,attrs:{"product-data":item}})})],2)]),_c('section',{staticClass:"articleSection"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"headline"},[_c('h1',[_vm._v("Legfrissebb cikkek")]),_c('hr'),_c('router-link',{staticClass:"grayButton transition",attrs:{"tag":"button","to":"/blog"}},[_vm._v(" További cikkek ")])],1),(_vm.model && _vm.model.blog)?_c('div',{staticClass:"row"},_vm._l((_vm.model.blog),function(item,index){return _c('BlogRow',{key:'home-blog' + index,attrs:{"blog":item}})}),1):_vm._e()])]),_c('section',{staticClass:"partnerSection"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row partners"},_vm._l((_vm.model.marka),function(item,index){return _c('div',{key:'marka-' + index,staticClass:"col-lg-2"},[_c('router-link',{attrs:{"to":{
                            name: 'ProductList',
                            params: {
                                slug: 'null-null-null'
                            },
                            query: { emarka: item.name }
                        }}},[_c('img',{staticClass:"img img-fluid",attrs:{"src":item.image}})])],1)}),0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"headline"},[_c('h1',[_vm._v("Gumigyártók")]),_c('hr')])}]

export { render, staticRenderFns }